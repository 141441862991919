import { text } from './policy.js';
import { 
    PolicyContainer, 
    Policy, 
    PolicyTitle, 
    PolicyText 
} from '../PolicyElements';


const PrivacyPolicy = () => {
    return (
        <PolicyContainer>
            <Policy>
                <PolicyTitle>Privacy Policy</PolicyTitle>
                <PolicyText>{text}</PolicyText>
            </Policy>
        </PolicyContainer>
    );
}

export default PrivacyPolicy