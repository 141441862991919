import sunandmoon from 'images/sunandmoon.svg';
import devices from 'images/devices.svg';
import clipboard from 'images/clipboard.svg';
import people from 'images/people.svg';
import lock from 'images/lock.svg';

export const about = {
  id: 'about',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Diary service',
  headline: 'Free diary service for all your devices',
  description:
    'Get access to our exclusive app that allows you to store diary entries and access them between devices',
  buttonLabel: 'Get Started',
  imgStart: false,
  img: devices,
  alt: 'Mobile and computer devices',
  dark: true,
  primary: true,
  darkText: false
};

export const discover = {
  id: 'discover',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Unlimited Access',
  headline: 'Login to your account at any time',
  description:
    'We have you covered no matter where you are located. All you need is an internet connection and a phone or computer.',
  buttonLabel: 'Learn More',
  imgStart: true,
  img: sunandmoon,
  alt: 'Sun and moon',
  dark: false,
  primary: false,
  darkText: true
};

export const share = {
  id: 'share',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Share with family',
  headline: 'Share your storage with friends and family',
  description:
    "Share your diary entries with your family and friends. It's that easy!",
  buttonLabel: 'Join our community',
  imgStart: false,
  img: people,
  alt: 'People',
  dark: false,
  primary: false,
  darkText: true
};

export const security = {
  id: 'security',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Secure entry storage',
  headline: 'Your entries are always safe with us',
  description:
    "Worried about your entries being stolen or read by us? We encrypt your entries with AES-256 encryption. That means your entries are safe with us.",
  buttonLabel: 'Store your entries securely',
  imgStart: true,
  img: lock,
  alt: 'People',
  dark: true,
  primary: true,
  darkText: false
};

export const signup = {
  id: 'signup',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Join our Team',
  headline: 'Get started in just a few clicks',
  description:
    "Creating an account is extremely easy, and writing entries is even easier!",
  buttonLabel: 'Start Now',
  imgStart: false,
  img: clipboard,
  alt: 'Clipboard',
  dark: false,
  primary: false,
  darkText: true
};