import React from "react";
import "App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "page";
import PrivacyPolicyPage from "page/PrivacyPolicy.js";
import TermsOfUsePage from "page/TermsOfUse.js";
import NotFoundPage from "page/NotFound.js";
import CookiePolicyPage from "page/CookiePolicy";
import CookieConsent from "react-cookie-consent";

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/privacy-policy" component={PrivacyPolicyPage} exact />
          <Route path="/terms-of-use" component={TermsOfUsePage} exact />
          <Route path="/cookie-policy" component={CookiePolicyPage} exact />
          <Route component={NotFoundPage} />
        </Switch>
      </Router>
    <CookieConsent 
                location="bottom"
                buttonText="Accept"
                style={{ background: "#1d1918" }}
                buttonStyle={{ background: "#992f30", color: "#fff" }}
            >We use cookies to improve user experience and analyze website traffic. By clicking “Accept“, you agree to our website's cookie use as described in our <a href="https://annal.app/cookie-policy">Cookie Policy</a>.</CookieConsent>
    </>
  );
}

export default App;
