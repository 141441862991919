import React, { useState, useEffect } from 'react';
import { FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { animateScroll as scroll } from 'react-scroll';
import annal from "images/annal-logo.svg"

import {
  MobileIcon,
  Nav,
  NavbarContainer,
  NavItem,
  NavLink,
  NavLogo,
  Logo,
  NavMenu,
  NavBtn,
  NavBtnLink
} from './NavbarElements';

const Navbar = ({ toggle, noFade }) => {
  let [scrollNav, setScrollNav] = useState(false);

  let changeNav = () => {}

  if (!noFade) {
    changeNav = () => {
      if (window.scrollY >= 80) {
        setScrollNav(true);
      } else {
        setScrollNav(false);
      }
    };
  }

  useEffect(() => {
    window.addEventListener('scroll', changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  scrollNav = noFade ? noFade : scrollNav

  let path = window.location.pathname
  let activehome = path === '/' ? '3px solid #EC696A' : ''
  let activeprivacy = path === '/privacy-policy' ? '3px solid #EC696A' : ''
  let activeterms = path === '/terms-of-use' ? '3px solid #EC696A' : ''
  let activecookie = path === '/cookie-policy' ? '3px solid #EC696A' : ''
  

  return (
    <>
      <IconContext.Provider value={{ color: "#ffffff" }}>
        <Nav noFade scrollNav={scrollNav}>
          <NavbarContainer>
            <NavLogo onClick={toggleHome} to='/'>
              <Logo src={annal} />
            </NavLogo>
            <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>
            <NavMenu>
            <NavItem>
                <NavLink style={{"border-bottom": activehome}} to='/'>
                  Home
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink style={{"border-bottom": activeprivacy}} to='/privacy-policy'>
                  Privacy Policy
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink style={{"border-bottom": activeterms}} to='/terms-of-use' >
                  Terms of Use
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink style={{"border-bottom": activecookie}} to='/cookie-policy' >
                  Cookie Policy
                </NavLink>
              </NavItem>
            </NavMenu>
            <NavBtn>
              <NavBtnLink href='https://app.annal.app/'>Go to app</NavBtnLink>
            </NavBtn>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;
