import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import ScrollToTop from 'components/ScrollToTop';
import NotFound from 'components/NotFound';
import Sidebar from 'components/Sidebar';
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';

function NotFoundPage() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="/css/notfound.css" />
      </Helmet>
      <ScrollToTop />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar noFade toggle={toggle} />
      <NotFound />
      <Footer sticky />
    </>
  );
}

export default NotFoundPage;