import notfoundimage from 'images/errors/404.png'
import { useHistory } from "react-router-dom";
import {
    NotFoundParent,
    NotFoundChild,
    ErrorImage,
    ErrorTitle,
    ErrorText,
    ErrorLink,
    ErrorDiv
} from './NotFoundElements';

const NotFound = () => {
    let history = useHistory();

    return (
        <NotFoundParent>
            <NotFoundChild>
                <ErrorImage src={notfoundimage} alt="404 image" />
                <ErrorDiv>
                    <ErrorTitle>Page not found!</ErrorTitle>
                    <ErrorText> The page you are looking for does not exist. </ErrorText>
                    <ErrorDiv side>
                        <ErrorLink onClick={history.goBack}>Go back</ErrorLink>
                        <ErrorLink href="/">Go home</ErrorLink>
                    </ErrorDiv>
                </ErrorDiv>
            </NotFoundChild>
        </NotFoundParent>
    );
}

export default NotFound