import React, { useState } from 'react';
import ScrollToTop from 'components/ScrollToTop';
import TermsOfUse from 'components/TermsOfUse';
import Sidebar from 'components/Sidebar';
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';

function TermsOfUsePage() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <ScrollToTop />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <TermsOfUse />
      <Footer sticky />
    </>
  );
}

export default TermsOfUsePage;