import React, { useState } from 'react';
import ScrollToTop from 'components/ScrollToTop';
import CookiePolicy from 'components/CookiePolicy';
import Sidebar from 'components/Sidebar';
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';

function CookiePolicyPage() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <ScrollToTop />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <CookiePolicy />
      <Footer sticky />
    </>
  );
}

export default CookiePolicyPage;