import styled from 'styled-components';

export const PolicyContainer = styled.div`
    display: flex;
    width: 100%;
    margin: 0;
    margin-top: 5rem;
    margin-bottom: 5rem;
`;

export const Policy = styled.div`
    margin-left: 20%;
    margin-right: 20%;
`;

export const PolicyTitle = styled.h1`
    font-size: 2.5rem;
    font-weight: bold;
`;

export const PolicyText = styled.p`
    font-size: 1.5rem;
    line-height: 1.5;
    white-space: pre-wrap;
`;