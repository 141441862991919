import { text } from './policy.js';
import { 
    PolicyContainer, 
    Policy, 
    PolicyTitle, 
    PolicyText 
} from '../PolicyElements';


const CookiePolicy = () => {
    return (
        <PolicyContainer>
            <Policy>
                <PolicyTitle>Cookie Policy</PolicyTitle>
                <PolicyText>{text}</PolicyText>
            </Policy>
        </PolicyContainer>
    );
}

export default CookiePolicy