import styled from 'styled-components';

export const NotFoundParent = styled.div`
    background-color: transparent;
    display: flex;
    flex: 1 0 auto;
    width: 100%;
    font-size: xx-large;
    justify-content: center;
`;

export const NotFoundChild = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ErrorImage = styled.img`
    max-width: 20%;
    display: block;
`;

export const ErrorTitle = styled.h3`
    text-color: #1d1918;
`;

export const ErrorText = styled.p`
    text-color: #1d1918;
`;

export const ErrorDiv = styled.div`
    display: flex;
    flex-direction: ${({ side }) => (side ? 'initial' : 'column')};
    justify-content: space-around;
`;

export const ErrorLink = styled.a`
    margin-top: 2rem;
    text-align: center;
    font-size: x-large;
    text-decoration: none;
    color: #eb6769;
    background-color: #1d1918;
    cursor: pointer;
    padding: 10px 22px;
    border-radius: 50px;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #fff;
        color: #1d1918;
    }
`;