import React from 'react';
import l1 from 'images/aspiring-writer.jpg';
import l2 from 'images/scribe.jpg';
import l3 from 'images/journalist.jpg';
import l4 from 'images/novelist.jpg';
import l5 from 'images/scrivener.jpg';
import contactus from 'images/contactus.jpg'
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP
} from './ServicesElements';

const Services = () => {
  return (
    <ServicesContainer id='services'>
      <ServicesH1>Account levels</ServicesH1>
      <ServicesH1 style={{fontSize: 16, marginTop: -50}}>(All amounts are monthly and in USD)</ServicesH1>
      <ServicesWrapper>
      <ServicesCard>
          <ServicesIcon src={l1} />
          <ServicesH2>Aspiring Writer (FREE)</ServicesH2>
          <ServicesP>
            Stores 5 entries
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={l2} />
          <ServicesH2>Scribe ($2.99)</ServicesH2>
          <ServicesP>
          Stores 365 entries
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={l3} />
          <ServicesH2>Journalist ($6.99)</ServicesH2>
          <ServicesP>
          Stores 1,000 entries
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={l4} />
          <ServicesH2>Novelist ($11.99)</ServicesH2>
          <ServicesP>
            Stores 3,000 entries
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={l5} />
          <ServicesH2>Scrivener ($19.99)</ServicesH2>
          <ServicesP>
            Stores 8,000 entries
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={contactus} />
          <ServicesH2>Even more entries?</ServicesH2>
          <ServicesP>
            Contact us
          </ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
