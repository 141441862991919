import React from 'react';
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarRouteText,
  SidebarRoute,
  SideBtnWrap
} from './SidebarElements';

const Sidebar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarRouteText
            to='/'
          >
            Home
          </SidebarRouteText>
          <SidebarRouteText
            to='/privacy-policy'
          >
            Privacy Policy
          </SidebarRouteText>
          <SidebarRouteText
            to='/terms-of-use'
          >
            Terms of Use
          </SidebarRouteText>
          <SidebarRouteText
            to='/cookie-policy'
          >
            Cookie Policy
          </SidebarRouteText>
        </SidebarMenu>
        <SideBtnWrap>
          <SidebarRoute href='https://app.annal.app'>Go to app</SidebarRoute>
        </SideBtnWrap>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
