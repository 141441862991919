import React, { useState } from 'react';
import Navbar from 'components/Navbar';
import Sidebar from 'components/Sidebar';
import Footer from 'components/Footer';
import HeroSection from 'components/HeroSection';
import InfoSection from 'components/InfoSection';
import {
  about,
  share,
  discover,
  signup,
  security,
} from 'components/InfoSection/Data';
import Services from 'components/Services';

function Home() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeroSection />
      <InfoSection {...about} />
      <InfoSection {...discover} />
      <Services />
      <InfoSection {...share} />
      <InfoSection {...security} />
      <InfoSection {...signup} />
      <Footer />
    </>
  );
}

export default Home;
