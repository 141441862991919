import React, { useState } from 'react';
import annal from "images/annal-full.svg"

import {
  HeroContainer,
  HeroBg,
  HeroContent,
  HeroImg,
  HeroP,
  Button,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight
} from './HeroElements';

function HeroSection() {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };
  return (
    <HeroContainer id='home'>
      <HeroBg>
      </HeroBg>
      <HeroContent>
        <HeroImg src={annal}></HeroImg>
        <HeroP>
          Sign up for a new account today and receive free premium for a month!
        </HeroP>
        <HeroBtnWrapper>
          <Button
            href='https://app.annal.app/'
            smooth={true}
            duration={500}
            spy={true}
            exact='true'
            offset={-80}
            primary='false'
            dark='false'
            onMouseEnter={onHover}
            onMouseLeave={onHover}
          >
            Go to app {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  );
}

export default HeroSection;
